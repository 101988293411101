import { customEvent } from './utils';
import { Notyf } from 'notyf';

window.tryRun("form", function () {
    let SendGoogleAnalytics = function (form) {
        var category = form.getAttribute('data-category');
        var action = form.getAttribute('data-action');
        var label = form.getAttribute('data-label-failed');

        if (window.ga && ga.create)
            ga('send', 'event', category, action, label);
    }

    let validateEmail = function (email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    let validateConditional = function (input) {
        var allConditionals = document.querySelectorAll("[data-val-conditional]");
        var valid = false
        for (var i = 0; i < allConditionals.length; i++) {
            if (allConditionals[i].checked) {
                valid = true;
            }
        }
    }

    let validateMax = function (input) {
        var maxChars = input.getAttribute("data-max");
        if (maxChars) {
            return input.value.length <= parseInt(maxChars);
        }

        return false;
    }

    let validatePhone = function (phone) {
        if (phone == "")
            return true;
        var re = /^[\+]?[(]?[0-9]{3,4}[)]?[-\s\.]?[0-9]{6,8}$/im;
        return re.test(String(phone).toLowerCase());
    };

    let getInputValue = function (element) {
        switch (element.nodeName) {
            case "TEXTAREA":
                return element.value;
                break;
            case "INPUT":
                switch (element.type) {
                    case "text":
                    case "file": 
                    case "hidden":

                        return element.value;
                    case "checkbox":
                        return element.checked ? element.value : "";
                }
                break;
            case "SELECT":
                return element.value;
        }
        return "";
    };

    let animateError = function (input, lastHeight = -1, lastTime = Date.now()) {
        window.requestAnimationFrame(function () {
            var time = Date.now();
            var delta = time - lastTime;

            var rect = input.error.getBoundingClientRect();
            var height = rect.bottom - rect.top;
            if (lastHeight == -1)
                lastHeight = height;
            var newHeight = 0;
            if (input.error.classList.contains("visible")) {
                if (height < lastHeight - 1) {
                    input.error.style.maxHeight = "unset";
                    return;
                }
                newHeight = height + (delta * 0.4);
            } else {
                if (lastHeight < 0) {
                    input.error.style.maxHeight = "";
                    return;
                }
                newHeight = height - (delta * 0.4);
            }
            input.error.style.maxHeight = newHeight + "px";

            animateError(input, newHeight, time);
        });
    }

    let showError = function (input, msg) {
        input.errorInner.innerText = msg;
        if (input.error.classList.contains("visible"))
            return;

        input.error.classList.add("visible");
        animateError(input);
    }

    let hideError = function (input) {
        if (!input.error.classList.contains("visible"))
            return;

        input.error.classList.remove("visible");
        animateError(input);
    }

    let validate = function (input, changes, removeErrors = false) {
        var valid = true;
        var tested = false;
        var msg = "";
        var parentValidates = false;
        var parentInputTagChecked = false;

        if (removeErrors) {
            if (!input.element.checked) {
                valid = true;
                tested = true;
                hideError(input);
                return true;
            }
        }


        //find out if element listen to parent
        if (input.element.getAttribute('data-validation-parent')) {
            let parentInput = input.element.getAttribute('data-validation-parent');

            if (parentInput) {
                let tag = document.getElementById(parentInput);

                if (tag) {
                    parentValidates = true;
                    parentInputTagChecked = tag.checked;
                }
            }
        }

        if (input.requiredMessage) {
            if (parentValidates) {
                if (parentInputTagChecked) {
                    valid &= getInputValue(input.element) != "";
                    tested = true;
                    msg = input.requiredMessage;

                    if (!valid) {
                        if (input.parentElement) {
                            if (input.category) {
                                var categoryParent = document.querySelector(`[data-category-id="${input.category}"]`);
                                if (categoryParent) {
                                    const parentIsExpanded = categoryParent.getAttribute('aria-expanded') == 'true';

                                    if (!parentIsExpanded)
                                        categoryParent.click();
                                }
                            }

                            var groupButton = input.parentElement.nextElementSibling.nextElementSibling;

                            if (groupButton) {
                                const isExpanded = groupButton.getAttribute('aria-expanded') == 'true';

                                if (!isExpanded)
                                    groupButton.click();

                            }
                        }
                        

                    }
                } else {
                    valid = true;
                    tested = true;
                    msg = input.requiredMessage;
                }
            } else {
                valid &= getInputValue(input.element) != "";
                tested = true;
                msg = input.requiredMessage;
            }
        }
        if (input.phoneMessage && valid) {
            valid &= validatePhone(getInputValue(input.element));
            tested = true;
            msg = input.phoneMessage;
        }
        if (input.emailMessage && valid) {
            valid &= validateEmail(getInputValue(input.element));
            tested = true;
            msg = input.emailMessage;
        }

        if (input.conditionalMessage && valid) {
            var tempValid = false;
            var allConditionals = document.querySelectorAll("[data-val-conditional]");
            for (var i = 0; i < allConditionals.length; i++) {
                if (allConditionals[i].checked) {
                    tempValid = true;
                }
            }
            valid &= tempValid
            tested = true;
            msg = input.conditionalMessage;
        }

        if (input.maxMessage && valid) {
            valid &= validateMax(input.element);
            tested = true;
            msg = input.maxMessage;
        }

        if (valid) {
            if (tested)
                hideError(input);
        } else {
            if (!changes) {
                showError(input, msg);
            }
        }

        return valid;
    };

    let handleChange = function (input) {
        if (input.dataForElement && input.dataForElements.length > 0) {
            var values = [];
            for (var i = 0; i < input.dataForElements.length; i++) {
                var value = getInputValue(input.dataForElements[i]);
                if (value != "")
                    values.push(value);
            }
            input.dataForElement.value = values.join("\n");
            input.dataForElement.dispatchEvent(new Event('change'));
        }

        if (input.element.id == "00N3H0000028Asa") {
            validate(input, true, true)
        } else if (input.error) {
            validate(input, true);
        }

        
    };

    let handleInput = function (input) {
        input.element.addEventListener("change", function () {
            handleChange(input);
        });
        input.element.addEventListener("keyup", function () {
            handleChange(input);
        });
    };

    let handleForm = function (form) {
        var inputs = [];

        var elements = form.querySelectorAll("[data-types]");
        for (var i = 0, element; element = form[i++];) {
            var input = {
                element: element,
                dataForElement: null,
                dataForElements: [],
                category: element.hasAttribute("data-category-parent-id") ? element.getAttribute("data-category-parent-id") : null,
                emailMessage: element.hasAttribute("data-val-email") ? element.getAttribute("data-val-email") : null,
                phoneMessage: element.hasAttribute("data-val-phone") ? element.getAttribute("data-val-phone") : null,
                requiredMessage: element.hasAttribute("data-val-required") ? element.getAttribute("data-val-required") : null,
                maxMessage: element.hasAttribute("data-max-message") ? element.getAttribute("data-max-message") : null,
                conditionalMessage: element.hasAttribute("data-val-conditional") ? element.getAttribute("data-val-conditional") : null,
                error: null,
                errorInner: null,
                parentElement: null,
                parentControl: false
            };

            if (input.emailMessage || input.phoneMessage || input.requiredMessage || input.conditionalMessage) {
                input.error = form.querySelectorAll("[data-valmsg-for='" + element.name + "']")[0];
                input.errorInner = document.createElement("div");
                if (!input.error) {
                    input.error = document.createElement("div");
                    input.element.parentElement.insertBefore(input.error, input.element.nextSibling);
                }
                input.error.classList.add("error");
                input.error.appendChild(input.errorInner);
            }

            if (input.element.hasAttribute("data-source")) {
                input.dataForElement = document.getElementById(input.element.getAttribute("data-source"));
                if (input.dataForElement)
                    input.dataForElements = form.querySelectorAll("[data-source='" + input.dataForElement.id + "']");
            }

            if (input.element.hasAttribute("data-validation-parent")) {
                input.parentElement = document.getElementById(input.element.getAttribute('data-validation-parent'));
                input.parentControl = true;
                inputs.push(input)
            }

            if (input.error || input.dataForElements.length > 0) {
                inputs.push(input);
                handleInput(input);
            }
        }

        form.addEventListener("submit", function (e) {
            e.preventDefault();
            disableButton(e.submitter)
            var clientKey = form.getAttribute("data-captcha-key");
            var action = form.getAttribute("data-captcha-action");
            grecaptcha.ready(function () {
                grecaptcha.execute(clientKey, { action: action }).then(function (token) {
                    form.querySelector('input[name$="GoogleCaptchaToken"]').value = token;
                }).then(function () {
                    var valid = true;
                    var inputsToIgnore = [];
                    var invalidItems = [];
                    for (var i = 0; i < inputs.length; i++) {
                        if (inputs[i].parentControl) {
                            if (!inputs[i].parentElement.checked) {
                                inputsToIgnore.push(inputs[i].element);
                            } 
                        }

                        valid &= validate(inputs[i], false);
                        if (!valid && !invalidItems.includes(inputs[i])) invalidItems.push(inputs[i]);
                    }


                    for (var i = 0; i < form.length; i++) {
                        if (inputsToIgnore.includes(form[i])) {
                            form[i].remove();
                        }
                    }
                    
                    SendGoogleAnalytics(form);

                    if (!valid) {
                        if (e.submitter.id == "reclamation-form-submit") {
                            var invalidInputs = invalidItems.filter(i => !inputsToIgnore.includes(i.element));
                            if (invalidInputs.length > 0) {
                                var target = invalidInputs[0];
                                if (target.parentElement) {
                                    target.parentElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
                                } else {
                                    target.element.scrollIntoView({ behavior: 'smooth', block: 'center' })
                                }
                            }
                        }
                        return;
                    }

                    var isJson = form.getAttribute("data-result-json");
                    var buildOnline = form.getAttribute("data-show-build-online");

                    var formData = new FormData(form);
                    var request = new XMLHttpRequest();
                    if (isJson)
                        request.overrideMimeType("application/json");
                    request.open("POST", form.action);
                    //request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

                    request.onreadystatechange = function () {
                        if (this.readyState === XMLHttpRequest.DONE) {
                            var resultMessage = "";
                            var isError = false;
                            var is200 = this.status == 200;
                            var errorMessage = "";
                            var jsonObj;

                            try {
                                jsonObj = JSON.parse(this.response);
                            } catch (err) {
                                if (!navigator.cookieEnabled) {
                                    isError = true;
                                    resultMessage = "Hoppsan!<br /><br />Ett fel inträffade: kunde inte hitta nödvändig cookie, kontrollera att webbläsaren accepterar cookies, uppdatera sidan och försök igen.";
                                } else {
                                    isError = true;
                                    resultMessage = "Hoppsan!<br /><br />Nu gick något fel. Var vänlig och uppdatera sidan och fyll i formuläret en gång till.";
                                }
                            }

                            if (jsonObj) {
                                isError = jsonObj.error;
                            }
                            if (!isError) {
                                if (is200) {
                                    if (jsonObj) {
                                        if (jsonObj.url != null) {
                                            customEvent.call(form, 'form', 'completed');
                                            document.location = jsonObj.url;
                                            return;
                                        } else {
                                            resultMessage = jsonObj.message;
                                            var hideDescription = form.getAttribute("data-hide-description");

                                            if (hideDescription) {
                                                var target = document.getElementById(hideDescription);

                                                if (target)
                                                    target.classList.add('hide');
                                            }
                                        }
                                    } else {
                                        resultMessage = this.response;
                                    }
                                } else {
                                    if (!navigator.cookieEnabled) {
                                        isError = true;
                                        resultMessage = "Hoppsan!<br /><br />Ett fel inträffade: kunde inte hitta nödvändig cookie, kontrollera att webbläsaren accepterar cookies, uppdatera sidan och försök igen.";
                                    } else {
                                        isError = true;
                                        resultMessage = "Hoppsan!<br /><br />Nu gick något fel. Var vänlig och uppdatera sidan och fyll i formuläret en gång till.";
                                    }
                                }
                            } else {
                                resultMessage = jsonObj.message;
                            }

                            if (isError) {
                                const notyf = new Notyf();
                                notyf.error({
                                    message: resultMessage,
                                    icon: false,
                                    duration: 0,
                                    position: {
                                        x: 'center',
                                        y: 'center'
                                    },
                                    ripple: false,
                                    dismissible: true
                                });
                                return;
                            }

                            customEvent.call(form, 'form', 'completed');
                            document.getElementById(form.getAttribute("data-result-id")).innerHTML = resultMessage;
                            if (form.getAttribute("data-scroll-submit") == "1" && !form.parentElement.classList.contains("modal-body")) {
                                setTimeout(function () {
                                    var top = form.offsetTop - (window.innerHeight / 2) + (form.offsetHeight / 2);
                                    window.scroll({
                                        top: top > form.offsetTop - 100 ? form.offsetTop - 100 : top,
                                        left: 0,
                                        behavior: 'smooth'
                                    });
                                }, 100);
                            }

                            if (buildOnline)
                                document.getElementById(form.getAttribute("data-result-id")).parentNode.querySelector('.build-online-url').style.display = 'block';

                            /*if (isJson) {

                                if (this.status == 200) {
                                    var jsonObj = JSON.parse(this.response);
                                    isError = jsonObj.error;
                                    if (jsonObj.url != null) {
                                        customEvent.call(form, 'form', 'completed');

                                        document.location = jsonObj.url;
                                    } else {
                                        resultMessage = jsonObj.message;
                                    }
                                }
                            } else {
                                resultMessage = this.response;
                            }

                            if (resultMessage != "") {
                                if (isError) {
                                    const notyf = new Notyf();

                                    notyf.error({
                                        message: resultMessage,
                                        duration: 5000,
                                        icon: false,
                                        position: {
                                            x: 'center',
                                            y: 'center'
                                        },
                                        ripple: false,
                                        dismissible: true
                                    });
                                    e.submitter.disabled = false;
                                    return;
                                } else {
                                    customEvent.call(form, 'form', 'completed');
                                }

                                document.getElementById(form.getAttribute("data-result-id")).innerHTML = resultMessage;
                                if (form.getAttribute("data-scroll-submit") == "1" && !form.parentElement.classList.contains("modal-body")) {
                                    setTimeout(function () {
                                        var top = form.offsetTop - (window.innerHeight / 2) + (form.offsetHeight / 2);
                                        window.scroll({
                                            top: top > form.offsetTop - 100 ? form.offsetTop - 100 : top,
                                            left: 0,
                                            behavior: 'smooth'
                                        });
                                    }, 100);
                                }

                                if (buildOnline)
                                    document.getElementById(form.getAttribute("data-result-id")).parentNode.querySelector('.build-online-url').style.display = 'block';
                            }*/
                        }
                        e.submitter.disabled = false;
                    };

                    request.send(formData);
                });
            });
        });
    };

    let forms = document.querySelectorAll("form[data-form]");
    let ignoredForms = document.querySelectorAll('form[data-val-ignore="True"]')
    for (var i = 0; i < forms.length; i++) {
        var ignore = false;
        for (var j = 0; j < ignoredForms.length; j++) {
            if (ignoredForms[j] == forms[i]) {
                ignore = true;
                continue;
            }
        }

        if (!ignore) {
            handleForm(forms[i]);
        }
    }

    function disableButton(button) {
        button.disabled = true;
        setTimeout(() => {
            button.disabled = false;
        }, 5000)
    }
});